<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">新建课程</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <a class="crumbs_item" @click="$router.go(-1)">课程列表 / </a>
          <span class="crumbs_item crumbs_last">{{$route.query.state=='2'?'编辑':'新建'}}课程</span>
        </div>
      </div>
    </div>

    <!-- 进度 -->
    <StepBar class="StepBar" :list="offline?['基础设置','特殊设置','购买设置']:['基础设置','特殊设置','购买设置']" :tabIndex="funtabIndex"/>

    <!-- 基础设置 -->
    <template v-if="tabIndex == 0">
      <a-row class="rows">
        <a-col class="left required" span="4">课程名称</a-col>
        <a-col class="right" span="8">
          <a-input placeholder="请输入课程名称" v-model="formData.courseName"/>
        </a-col>
        <a-col span="11" style="text-align:right;">
          <a href="https://cos.hxclass.cn/prod/template/course/%E8%AF%BE%E7%A8%8B%E5%9F%BA%E7%A1%80%E8%AE%BE%E7%BD%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx" target="_blank" rel="noopener noreferrer">
            <a-button style="margin-right:10px;">下载导入模板</a-button>
          </a>
          <a-upload
            :showUploadList="false"
            name="BasicInfo"
            :customRequest="fileAction"
          >
            <a-button type="primary">导入基础信息</a-button>
          </a-upload>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">关联偏好(可多选)</a-col>
        <a-col class="right" span="19">
          <a-checkbox-group class="radioGroup" v-model="formData.courseLikeIdList">
            <a-checkbox :value="item.likeId" v-for="item in preferenceList" :key="'likeId'+item.likeId">{{item.likeLabelName}}</a-checkbox>
          </a-checkbox-group>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">移动端</a-col>
        <a-col class="right" span="8">
          <div class="tips">用于商城列表、证书课程、收藏列表、学习(我的课程)</div>
          <div>
            <span class="ant-upload-picture-card-wrapper" v-if="formData.mobileListPicture">
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                <span role="button" tabindex="0" class="ant-upload">
                  <div class="pictures">
                    <img style="max-height:100px;max-width:100px;" :src="formData.mobileListPicture" alt="avatar" />
                    <div class="icon">
                      <a :href="formData.mobileListPicture" target="_blank" rel="noopener noreferrer">
                        <a-icon type="eye" class="i" />
                      </a>
                      <a-icon @click="formData.mobileListPicture = ''" type="delete" class="i" />
                    </div>
                  </div>
                </span>
              </div>
            </span>
            <a-upload
              v-else
              name="mobileListPicture"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <div>
                <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
          </div>
          <div class="Tips">建议尺寸750*750，最多传1张</div>
        </a-col>
        <a-col class="left required" span="4">移动端</a-col>
        <a-col class="right" span="8">
          <div class="tips">用于商品详情、精选中套餐包含商品列表</div>
          <div>
            <span class="ant-upload-picture-card-wrapper" v-if="formData.mobileDetailPicture">
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                <span role="button" tabindex="0" class="ant-upload">
                  <div class="pictures">
                    <img style="max-height:100px;max-width:100px;" :src="formData.mobileDetailPicture" alt="avatar" />
                    <div class="icon">
                      <a :href="formData.mobileDetailPicture" target="_blank" rel="noopener noreferrer">
                        <a-icon type="eye" class="i" />
                      </a>
                      <a-icon @click="formData.mobileDetailPicture = ''" type="delete" class="i" />
                    </div>
                  </div>
                </span>
              </div>
            </span>
            <a-upload v-else
              name="mobileDetailPicture"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <div>
                <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
          </div>
          <div class="Tips">建议尺寸：750*530px，最多传一张</div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">移动端</a-col>
        <a-col class="right" span="8">
          <div class="tips">用于首页重磅课程、热门课程、免费课程</div>
          <div>
            <span class="ant-upload-picture-card-wrapper" v-if="formData.mobileChiefPicture">
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                <span role="button" tabindex="0" class="ant-upload">
                  <div class="pictures">
                    <img style="max-height:100px;max-width:100px;" :src="formData.mobileChiefPicture" alt="avatar" />
                    <div class="icon">
                      <a :href="formData.mobileChiefPicture" target="_blank" rel="noopener noreferrer">
                        <a-icon type="eye" class="i" />
                      </a>
                      <a-icon @click="formData.mobileChiefPicture = ''" type="delete" class="i" />
                    </div>
                  </div>
                </span>
              </div>
            </span>
            <a-upload v-else
              name="mobileChiefPicture"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              list-type="picture-card"
              :showUploadList="false"
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <div>
                <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
          </div>
          <div class="Tips">建议尺寸450*258，最多传1张</div>
        </a-col>
        <a-col class="left required" span="4">PC端</a-col>
        <a-col class="right" span="8">
          <div class="tips">用于商城列表图、首页列表图、收藏列表图、重磅课程</div>
          <div>
            <span class="ant-upload-picture-card-wrapper" v-if="formData.pcListPicture">
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                <span role="button" tabindex="0" class="ant-upload">
                  <div class="pictures">
                    <img style="max-height:100px;max-width:100px;" :src="formData.pcListPicture" alt="avatar" />
                    <div class="icon">
                      <a :href="formData.pcListPicture" target="_blank" rel="noopener noreferrer">
                        <a-icon type="eye" class="i" />
                      </a>
                      <a-icon @click="formData.pcListPicture = ''" type="delete" class="i" />
                    </div>
                  </div>
                </span>
              </div>
            </span>
            <a-upload v-else
              name="pcListPicture"
              list-type="picture-card"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :showUploadList="false"
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <div>
                <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
          </div>
          <div class="Tips">建议尺寸360*313，最多传1张</div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">PC端</a-col>
        <a-col class="right" span="8">
          <div class="tips">用于商品详情、热门推荐</div>
          <div>
            <span class="ant-upload-picture-card-wrapper" v-if="formData.pcDetailPicture">
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                <span role="button" tabindex="0" class="ant-upload">
                  <div class="pictures">
                    <img style="max-height:100px;max-width:100px;" :src="formData.pcDetailPicture" alt="avatar" />
                    <div class="icon">
                      <a :href="formData.pcDetailPicture" target="_blank" rel="noopener noreferrer">
                        <a-icon type="eye" class="i" />
                      </a>
                      <a-icon @click="formData.pcDetailPicture = ''" type="delete" class="i" />
                    </div>
                  </div>
                </span>
              </div>
            </span>
            <a-upload v-else
              name="pcDetailPicture"
              list-type="picture-card"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :showUploadList="false"
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <div>
                <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
          </div>
          <div class="Tips">建议尺寸750*750，最多传1张</div>
        </a-col>
        <a-col class="left" span="4">二维码</a-col>
        <a-col class="right" span="8">
          <div class="tips">用于购买商品后添加客服</div>
          <div>
            <span class="ant-upload-picture-card-wrapper" v-if="formData.customerCode">
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                <span role="button" tabindex="0" class="ant-upload">
                  <div class="pictures">
                    <img style="max-height:100px;max-width:100px;" :src="formData.customerCode" alt="avatar" />
                    <div class="icon">
                      <a :href="formData.customerCode" target="_blank" rel="noopener noreferrer">
                        <a-icon type="eye" class="i" />
                      </a>
                      <a-icon @click="formData.customerCode = ''" type="delete" class="i" />
                    </div>
                  </div>
                </span>
              </div>
            </span>
            <a-upload v-else
              name="customerCode"
              list-type="picture-card"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :showUploadList="false"
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <div>
                <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
          </div>
          <div class="Tips">建议尺寸160*160，最多传1张</div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">课程简介</a-col>
        <a-col class="right" span="8">
          <a-textarea placeholder="请输入课程简介" v-model="formData.introduction"/>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">视频数量</a-col>
        <a-col class="right" span="8">
          <a-input-number :precision="0" :min="1" placeholder="视频数量" style="width:100px;" v-model="formData.classHour"/>
          <span style="font-size:14px;color:#333;margin-left:12px;">个</span>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">课程类别</a-col>
        <a-col class="right" span="8">
          <a-tree-select style="width:100%;"
            :tree-data="CategorySelectList"
            v-model="formData.courseCategoryId"
            @change="changeCategoryId"
            placeholder="请选择课程类别"
            :replaceFields="{
              children:'childList', title:'name', key:'courseCategoryId', value: 'courseCategoryId' 
            }"
            :treeDefaultExpandAll="true"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
          </a-tree-select>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">商品编码</a-col>
        <a-col class="right" span="4">
          <a-select style="width:100%;" v-model="formData.codeType">
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option value="1">自产A</a-select-option>
            <a-select-option value="2">外销B</a-select-option>
          </a-select>
        </a-col>
        <!-- <a-col class="right" span="4" style="margin-left: 8px;">
          <a-input v-model="formData.productCode" placeholder="请输入编码"/>
        </a-col> -->
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">授课老师</a-col>
        <a-col class="right" span="8">
          <a-select style="width:100%;" mode="multiple" v-model="formData.courseTeacherIdList" :filterOption="filterInstructorList" placeholder="请选择授课老师">
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="item.teacherId" v-for="item in InstructorList" :key="'InstructorList'+item.name">{{item.name}}</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="4">相关证书</a-col>
        <a-col class="right" span="20">
          <div v-for="(item,index) in formData.courseRelevantCertificate" :key="'courseRelevantCertificate'+index" style="overflow: hidden;">
            <div class="ant-upload ant-upload-select ant-upload-select-picture-card" v-if="item.picture">
              <span role="button" tabindex="0" class="ant-upload">
                <div class="pictures">
                  <img style="max-height:100px;max-width:100px;" :src="item.picture" alt="avatar" />
                  <div class="icon">
                    <a :href="item.picture" target="_blank" rel="noopener noreferrer">
                      <a-icon type="eye" class="i" />
                    </a>
                    <a-icon @click="item.picture = ''" type="delete" class="i" />
                  </div>
                </div>
              </span>
            </div>
            <a-upload v-else
              :name="'courseRelevantCertificate-'+index"
              list-type="picture-card"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :showUploadList="false"
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
              style="width:auto;"
            >
              <div>
                <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <a-input style="width:240px;vertical-align: top;" v-model="item.name" placeholder="请输入证书名称"/>
            <span style="vertical-align: top;line-height: 32px;margin-left: 5px;font-size: 14px;">
              <a v-if="index==0" @click="addCourseRelevantCertificate()">添加+</a>
              <a v-else @click="removeCourseRelevantCertificate(item.uid)">删除</a>
            </span>
          </div>
          <div class="Tips">可传多张证书图片，考试证书/课程证书</div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="4">客服介绍</a-col>
        <a-col class="right" span="8">
          <a-textarea placeholder="请输入客服介绍" v-model="formData.customerIntroduce"/>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">移动端课程详情</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor1"
            :initContent="initMobileDetail"
            @input="getContent($event,'mobileDetail')"
          />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">PC端课程详情</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor2"
            :initContent="initPcDetail"
            @input="getContent($event,'pcDetail')"
          />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="4">移动端学习指导</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor3"
            :initContent="initMobileStudentInstructions"
            @input="getContent($event,'mobileStudentInstructions')"
          />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="4">PC端学习指导</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor4"
            :initContent="initPcStudentInstructions"
            @input="getContent($event,'pcStudentInstructions')"
          />
        </a-col>
      </a-row>
      <!-- 13号需求变更了，隐藏报名指南 -->
      <!-- <a-row class="rows">
        <a-col class="left" span="4">移动端报名指南</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor5"
            :initContent="initMobileRegistrationGuide"
            @input="getContent($event,'mobileRegistrationGuide')"
          />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="4">PC端报名指南</a-col>
        <a-col class="right" span="19">
          <UE
            editorId="introduceEditor6"
            :initContent="initPcRegistrationGuide"
            @input="getContent($event,'pcRegistrationGuide')"
          />
        </a-col>
      </a-row> -->
      <a-row class="rows">
        <a-col span="20" offset="4">
          <a-button :loading="loadingBtn" type="primary" style="margin-right:30px;" @click="onStorage()">暂存</a-button>
          <a-button :loading="loadingBtn" @click="!offline?tabIndex=2:tabIndex=2">下一步</a-button>
        </a-col>
      </a-row>
    </template>

    <!-- 视频管理 -->
    <template v-else-if="!offline && tabIndex == 1 && false">
      <a-row class="rows">
        <a-col class="left required" span="4">课程目录设置</a-col>
        <a-col class="right" span="7">
          <!-- <a-radio-group class="radioGroup" v-model="formData.setCatalogue">
            <a-radio :disabled="userStudy == '1'" :value="0">无需设置目录</a-radio>
            <a-radio :disabled="userStudy == '1'" :value="1">需要设置目录</a-radio>
          </a-radio-group> -->
        </a-col>
        <a-col span="12" style="text-align:right;" v-if="formData.setCatalogue && userStudy != '1'">
          <a style="margin-right:30px;" href="https://cos.hxclass.cn/prod/template/course/%E8%AF%BE%E7%A8%8B%E7%9B%AE%E5%BD%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx" target="_blank" rel="noopener noreferrer">
            <a-button>下载目录模版</a-button>
          </a>
          <a style="margin-right:30px;" href="https://cos.hxclass.cn/prod/template/exam/%E9%A2%98%E7%9B%AE%E6%A8%A1%E6%9D%BF.xls" target="_blank" rel="noopener noreferrer">
            <a-button>下载题目模版</a-button>
          </a>
          <a-upload
            :showUploadList="false"
            name="CatalogTemplate"
            :customRequest="fileAction"
            style="margin-right:30px;"
            v-if="formData.courseCatalogueList && formData.courseCatalogueList.length == 0"
          >
            <a-button type="primary">导入目录</a-button>
          </a-upload>
          <a-button v-else type="primary" @click="importConfirmation=true" style="margin-right:30px;">导入目录</a-button>
          <a-button @click="$refs.Modal.open({
            title: '新建目录',
            width: '500px',
            type: 'newDirectory'
          })">新建目录</a-button>
        </a-col>
      </a-row>
      <a-row class="rows" v-if="formData.setCatalogue">
        <a-col class="right" span="21" :offset="2">
          <a-table
            style="margin-top:0;"
            class="table-template"
            childrenColumnName="childList"
            :expandIcon="expandIcon"
            :columns="(userStudy == '1' ? columns5 : columns)"
            :data-source="formData.courseCatalogueList1"
            :defaultExpandAllRows="true"
            :pagination="false"
            :rowKey="item=>item.uid"
          >
            <!-- 主讲人 -->
            <template slot="teacherIdList" slot-scope="item">
              {{funTeacherIdList(item)}}
            </template>

            <!-- 时长 -->
            <template slot="duration" slot-scope="item">
              {{formatSeconds(item)}}
            </template>

            <!-- 终端展示排序 -->
            <template slot="show" slot-scope="item,row">
              <template v-if="!(row.first==1&&row.last==1)">
                <a v-if="row.first!=1" @click="moveCatalogue(row.uid,1)">前移</a>
                <span v-if="row.first==0 && row.last==0"> | </span>
                <a v-if="row.last!=1" @click="moveCatalogue(row.uid,2)">后移</a>
              </template>
              <span v-else>-</span>
            </template>

            <!-- 目录操作 -->
            <template slot="Directory" slot-scope="item,row">
              <a @click="$refs.Modal.open({
                title: '新建子目录',
                width: '500px',
                type: 'newChildrenDirectory'
              },row)">新建子目录</a>
              <span> | </span>
              <a @click="$refs.Modal.open({
                title: '编辑目录',
                width: '500px',
                type: 'newDirectory',
                state: 2
              },row)">编辑</a>
              <span> | </span>
              <a @click="removeCatalogue(row, row.uid)">删除</a>
            </template>

            <!-- 视频操作 -->
            <template slot="videoFileId" slot-scope="item,row">
              <template v-if="!!item">
                <a @click="$refs.Modal.open({
                  title: '编辑视频',
                  width: '600px',
                  type: 'uploadVideo',
                  state: 2
                },row)">编辑</a>
                <span> | </span>
                <a @click="removeVideo(row)">删除</a>
              </template>
              <a v-else @click="$refs.Modal.open({
                title: '上传视频',
                width: '600px',
                type: 'uploadVideo'
              },row)">上传</a>
            </template>

            <!-- 小节自测操作 -->
            <template slot="SelfTest" slot-scope="item,row">
              <template v-if="userStudy == '1'">
                <a v-if="(row.questionIdList && row.questionIdList.length>0) || (row.questionList && row.questionList.length>0)"
                  @click="$refs.Modal.open({
                  title: '查看小节自测题目',
                  width: '1000px',
                  type: 'SectionTest',
                  state: 3
                },row)">查看题目</a>
                <span v-else>-</span>
              </template>
              <template v-else>
                <a-upload
                  :showUploadList="false"
                  :name="'TopicTemplate,'+row.uid"
                  :customRequest="fileAction"
                >
                  <a>导入</a>
                </a-upload>
                <span> | </span>
                <template v-if="(row.questionIdList && row.questionIdList.length>0) || (row.questionList && row.questionList.length>0)">
                  <a @click="$refs.Modal.open({
                    title: '编辑小节自测题目',
                    width: '1000px',
                    type: 'SectionTest',
                    state: 2
                  },row)">编辑</a>
                  <span> | </span>
                  <a @click="removeSectionTest(row.uid)">删除</a>
                </template>
                <a v-else @click="$refs.Modal.open({
                  title: '选择小节自测题目',
                  width: '1000px',
                  type: 'SectionTest'
                },row)">新建</a>
              </template>
            </template>
          </a-table>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">免费试看设置</a-col>
        <a-col class="right" span="20">
          <a-radio-group class="radioGroup" v-model="formData.freeTrialType">
            <a-radio :disabled="userStudy == '1'" value="1" style="line-height:32px;">不设置免费试看</a-radio>
            <a-radio :disabled="userStudy == '1'" value="2" style="line-height:32px;">
              <span>可免费试看</span>
              <a-input-number :precision="0" :min="1" :disabled="userStudy == '1'" style="width:100px;margin:0 8px;" v-if="formData.freeTrialType=='2'" v-model="formData.freeTrialMinute"/>
              <span v-else> {{formData.freeTrialMinute?formData.freeTrialMinute:'-'}} </span>
              <span>分钟</span>
            </a-radio>
            <a-radio :disabled="userStudy == '1'" value="3" style="display:block;line-height:32px;">
              <span>此章节可试看</span>
              <a v-if="userStudy != '1' && formData.freeTrialType == '3'" style="margin-left:8px;" @click="onAddChapter()">添加章节</a>
            </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row class="rows" style="margin-top:0;">
        <a-col class="right" span="20" :offset="4">
          <div class="tryChapterList">
            <div v-for="(item,index) in getTryChapter()" :key="'tryChapterList'+index">
              <span>{{item.sectionName}}</span>
              <a style="margin-left: 30px;" @click="removeTryChapter(item)">删除</a>
            </div>
          </div>
          <div style="font-size:14px;padding-left:24px;" v-if="getTryChapter() && getTryChapter().length > 0">
            <span>以上章节可免费试看</span>
            <a-input-number :precision="0" :min="1" style="width:100px;margin:0 8px;" v-if="formData.freeTrialType=='3'" v-model="formData.freeTrialMinute"/>
            <span v-else> {{formData.freeTrialMinute?formData.freeTrialMinute:'-'}} </span>
            <span>分钟</span>
          </div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">课程观看设置</a-col>
        <a-col class="right" span="20">
          <div>
            <span class="fontSize">首次观看：</span>
            <a-checkbox :disabled="userStudy == '1'" v-model="formData.firstWatchType">可拖拽</a-checkbox>
            <a-checkbox :disabled="userStudy == '1'" v-model="formData.firstSpeedType">可倍速</a-checkbox>
          </div>
          <div>
            <span class="fontSize">再次观看：</span>
              <a-checkbox :disabled="userStudy == '1'" v-model="formData.againWatch">可拖拽</a-checkbox>
              <a-checkbox :disabled="userStudy == '1'" v-model="formData.againSpeed">可倍速</a-checkbox>
          </div>
          <div style="padding:8px 0;">
            <a-checkbox :disabled="userStudy == '1'" v-model="formData.watchByCatalogueOrder">必须按目录顺序进行观看</a-checkbox>
          </div>
          <div style="display: flex;">
            <span class="fontSize">观看条件：</span>
            <div class="fontSize">
              <a-checkbox :disabled="userStudy == '1'" v-model="viewCondition1">必须通过以下考试</a-checkbox>
              <a v-if="viewCondition1 && userStudy != '1'" style="margin-left:8px;" @click="$refs.Modal.open({
                title: '选择考试',
                width: '700px',
                type: 'SelectiveExam',
                checked: getCourseWatchLimitList(formData.courseWatchLimitList,1)
              })">选择考试</a>
              <div style="padding-left:54px;">
                <!-- 观看条件类型1.必须通过以下考试 2.必须获得以下考试证书 -->
                <div v-for="(item,index) in getCourseWatchLimitList(formData.courseWatchLimitList,1)" :key="'ExamNameList'+index">
                  <span>{{item.name}}</span>
                  <a v-if="userStudy != '1'" style="margin-left: 30px;" @click="removeCourseWatchLimitList(item.dataId,1)">删除</a>
                </div>
              </div>
              <a-checkbox :disabled="userStudy == '1'" v-model="viewCondition2">必须获得以下考试证书</a-checkbox>
              <a v-if="viewCondition2 && userStudy != '1'" style="margin-left:8px;" @click="$refs.Modal.open({
                title: '选择证书',
                width: '700px',
                type: 'SelectiveCertificate',
                checked: getCourseWatchLimitList(formData.courseWatchLimitList,2)
              })">选择证书</a>
              <div style="padding-left:54px;">
                <div v-for="(item,index) in getCourseWatchLimitList(formData.courseWatchLimitList,2)" :key="'CertificateNameList'+index">
                  <span>{{item.name}}</span>
                  <a v-if="userStudy != '1'" style="margin-left: 30px;" @click="removeCourseWatchLimitList(item.dataId,2)">删除</a>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="20" offset="4">
          <a-button :loading="loadingBtn" type="primary" style="margin-right:30px;" @click="onStorage()">暂存</a-button>
          <a-button :loading="loadingBtn" @click="tabIndex=0" style="margin-right:30px;">上一步</a-button>
          <a-button :loading="loadingBtn" @click="tabIndex=2">下一步</a-button>
        </a-col>
      </a-row>
    </template>

    <!-- 特殊设置 -->
    <template v-else-if="tabIndex == 2">
      <a-row class="rows">
        <a-col class="left required" span="4">免费试看设置</a-col>
        <a-col class="right" span="20">
          <a-radio-group class="radioGroup" v-model="formData.freeTrialType">
            <a-radio :disabled="userStudy == '1'" value="1" style="line-height:32px;">不设置免费试看</a-radio>
            <a-radio :disabled="userStudy == '1'" value="2" style="line-height:32px;">
              <span>可免费试看</span>
              <a-input-number :precision="0" :min="1" :disabled="userStudy == '1'" style="width:100px;margin:0 8px;" v-if="formData.freeTrialType=='2'" v-model="formData.freeTrialMinute"/>
              <span v-else> {{formData.freeTrialMinute?formData.freeTrialMinute:'-'}} </span>
              <span>分钟</span>
            </a-radio>
            <a-radio :disabled="userStudy == '1'" value="3" style="display:block;line-height:32px;">
              <span>此章节可试看</span>
              <a v-if="userStudy != '1' && formData.freeTrialType == '3'" style="margin-left:8px;" @click="onAddChapter()">添加章节</a>
            </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row class="rows" style="margin-top:0;">
        <a-col class="right" span="20" :offset="4">
          <div class="tryChapterList">
            <div v-for="(item,index) in getTryChapter()" :key="'tryChapterList'+index">
              <span>{{item.sectionName}}</span>
              <a style="margin-left: 30px;" @click="removeTryChapter(item)">删除</a>
            </div>
          </div>
          <div style="font-size:14px;padding-left:24px;" v-if="getTryChapter() && getTryChapter().length > 0">
            <span>以上章节可免费试看</span>
            <a-input-number :precision="0" :min="1" style="width:100px;margin:0 8px;" v-if="formData.freeTrialType=='3'" v-model="formData.freeTrialMinute"/>
            <span v-else> {{formData.freeTrialMinute?formData.freeTrialMinute:'-'}} </span>
            <span>分钟</span>
          </div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">课程观看设置</a-col>
        <a-col class="right" span="20">
          <div>
            <span class="fontSize">首次观看：</span>
            <a-checkbox :disabled="userStudy == '1'" v-model="formData.firstWatchType">可拖拽</a-checkbox>
            <a-checkbox :disabled="userStudy == '1'" v-model="formData.firstSpeedType">可倍速</a-checkbox>
          </div>
          <div>
            <span class="fontSize">再次观看：</span>
              <a-checkbox :disabled="userStudy == '1'" v-model="formData.againWatch">可拖拽</a-checkbox>
              <a-checkbox :disabled="userStudy == '1'" v-model="formData.againSpeed">可倍速</a-checkbox>
          </div>
          <div style="padding:8px 0;">
            <a-checkbox :disabled="userStudy == '1'" v-model="formData.watchByCatalogueOrder">必须按目录顺序进行观看</a-checkbox>
          </div>
          <div style="display: flex;">
            <span class="fontSize">观看条件：</span>
            <div class="fontSize">
              <a-checkbox :disabled="userStudy == '1'" v-model="viewCondition1">必须通过以下考试</a-checkbox>
              <a v-if="viewCondition1 && userStudy != '1'" style="margin-left:8px;" @click="$refs.Modal.open({
                title: '选择考试',
                width: '700px',
                type: 'SelectiveExam',
                checked: getCourseWatchLimitList(formData.courseWatchLimitList,1)
              })">选择考试</a>
              <div style="padding-left:54px;">
                <!-- 观看条件类型1.必须通过以下考试 2.必须获得以下考试证书 -->
                <div v-for="(item,index) in getCourseWatchLimitList(formData.courseWatchLimitList,1)" :key="'ExamNameList'+index">
                  <span>{{item.name}}</span>
                  <a v-if="userStudy != '1'" style="margin-left: 30px;" @click="removeCourseWatchLimitList(item.dataId,1)">删除</a>
                </div>
              </div>
              <a-checkbox :disabled="userStudy == '1'" v-model="viewCondition2">必须获得以下考试证书</a-checkbox>
              <a v-if="viewCondition2 && userStudy != '1'" style="margin-left:8px;" @click="$refs.Modal.open({
                title: '选择证书',
                width: '700px',
                type: 'SelectiveCertificate',
                checked: getCourseWatchLimitList(formData.courseWatchLimitList,2)
              })">选择证书</a>
              <div style="padding-left:54px;">
                <div v-for="(item,index) in getCourseWatchLimitList(formData.courseWatchLimitList,2)" :key="'CertificateNameList'+index">
                  <span>{{item.name}}</span>
                  <a v-if="userStudy != '1'" style="margin-left: 30px;" @click="removeCourseWatchLimitList(item.dataId,2)">删除</a>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row class="rows" v-if="!offline">
        <a-col class="left required" span="4">开班设置</a-col>
        <a-col class="right" span="20">
          <a-radio-group class="radioGroup" v-model="formData.setOpenClass">
            <a-radio :disabled="userStudy == '1'" value="0">无需设置开班时间</a-radio>
            <a-radio :disabled="userStudy == '1'" value="1">需要设置开班时间</a-radio>
          </a-radio-group>
          <a-button type="primary" v-if="formData.setOpenClass=='1'" @click="$refs.Modal.open({
            title: '设置开班时间',
            width: '500px',
            type: 'SelectOpenClass'
          })">新增班级</a-button>
        </a-col>
      </a-row>
      <a-row class="rows" v-if="formData.setOpenClass=='1' && !offline" style="margin-top:0;">
        <a-col class="right" span="19" :offset="4">
          <a-table
            class="table-template"
            @change="onOpenTimePage"
            :rowKey="item=>item.uid"
            :columns="columns1"
            :data-source="formData.courseOpenTimeList"
            :pagination="{
              total:total,
              current: openTimeNumber,
              pageSizeOptions: ['5','10','20','50'],
              defaultPageSize: openTimePageSize, 
              showSizeChanger: true,
              showTotal: function(total, range){
              return `共${total}条`}} ">
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ (openTimeNumber - 1) * openTimePageSize + i + 1 }}
              </div>
            </template>

            <template slot="operation" slot-scope="item, row">
              <div class="btn_router_link" v-if="userStudy != '1' || !row.openTimeId">
                <a @click="$refs.Modal.open({
                  title: '设置开班时间',
                  width: '500px',
                  type: 'SelectOpenClass',
                  state: 2
                },row)">编辑</a>
                  <span>|</span>
                  <a @click="removeSelectOpenClass(row.uid)">删除</a>
                </div>
              <span v-else>-</span>
            </template>
          </a-table>
        </a-col>
      </a-row>
      <template v-if="offline">
        <a-row class="rows">
          <a-col class="left" :class="offline?'required':''" span="4">培训场次</a-col>
          <a-col class="right" span="20">
            <span class="fontSize" style="color:#999;">(该设置仅针对课程类别为线下培训时有效)</span>
          </a-col>
        </a-row>
        <a-row class="rows">
          <a-col class="right" span="19" offset="4">
            <a-radio-group v-model="formData.offlineSameAddr">
              <a-radio value="1">所有场次在同一地点</a-radio>
              <template v-if="formData.offlineSameAddr=='1'">
                <Province ref="Province" @regionCity='FunProvince' :value="addressValue"></Province>
                <a-input style="width:240px;margin-left:8px;" v-model="ProvinceAddress" placeholder="请输入详细地址"/>
                <a-button type="primary" style="margin-left:8px;" @click="$refs.Modal.open({
                  title: '新增场次',
                  width: '500px',
                  type: 'FixedPlaceTraining'
                })">新增场次</a-button>
                <div>
                  <a-table
                    class="table-template"
                    :rowKey="item=>item.uid"
                    :columns="columns2"
                    :data-source="returnFixedPlaceTrainingList"
                    style="margin-top:10px;"
                  >
                    <template slot="operation" slot-scope="item, row">
                      <a @click="$refs.Modal.open({
                        title: '编辑场次',
                        width: '500px',
                        type: 'FixedPlaceTraining',
                        state: 2
                      },row)">编辑</a>
                      <span> | </span>
                      <a @click="removeFixedPlaceTraining('FixedPlaceTrainingList',row.uid)">删除</a>
                    </template>
                  </a-table>
                </div>
              </template>
              <a-radio value="0">培训场次在不同地点</a-radio>
              <template v-if="formData.offlineSameAddr=='0'">
                <a-button type="primary" style="margin-left:8px;" @click="$refs.Modal.open({
                  title: '新增场次',
                  width: '500px',
                  type: 'NonFixedPlaceTraining'
                })">新增场次</a-button>
                <div>
                  <a-table
                    style="margin-top:10px;"
                    class="table-template"
                    :rowKey="item=>item.uid"
                    :columns="columns3"
                    :data-source="returnNonFixedPlaceTrainingList"
                  >
                    <template slot="operation" slot-scope="item, row">
                      <a @click="$refs.Modal.open({
                        title: '新增场次',
                        width: '500px',
                        type: 'NonFixedPlaceTraining',
                        state: 2
                      },row)">编辑</a>
                      <span> | </span>
                      <a @click="removeFixedPlaceTraining('NonFixedPlaceTrainingList',row.uid)">删除</a>
                    </template>
                  </a-table>
                </div>
              </template>
            </a-radio-group>
          </a-col>
        </a-row>
      </template>
      <a-row class="rows">
        <a-col class="left required" span="4">课程有效期设置</a-col>
        <a-col class="right" span="20">
          <div class="fontSize">
            <span>有效期设置：</span>
            <a-input-number :precision="0" :min="1" v-model="formData.validDays" :disabled="formData.validDayType == '2' || userStudy == '1'" style="width:120px;margin-left:8px;"/>
            <span style="margin-left:8px;">天；</span>
            <a-checkbox :disabled="userStudy == '1'" style="margin-left:8px;line-height:32px;" :defaultChecked="formData.validDayType == '2'" @change="changeTermValidity">长期</a-checkbox>
          </div>
          <div class="fontSize" style="margin-top:10px;" v-if="formData.validDayType != '2'">
            <span>有效期开始计算节点：</span>
            <a-radio-group class="radioGroup" v-model="formData.validStartType">
              <template v-if="SectionTestLength > 0">
                <a-radio value="1" :disabled="userStudy == '1'">
                  <span>开始做第</span>
                  <a-input-number :precision="0" :min="1" :disabled="userStudy == '1' || formData.validStartType != '1'" v-model="formData.validStartSectionId" style="width:120px;margin-left:8px;"/>
                  <span style="margin-left:8px;">节小节自测起</span>
                </a-radio>
              </template>
              <template v-if="formData.setOpenClass=='1' && formData.courseOpenTimeList && formData.courseOpenTimeList.length > 0">
                <a-radio value="2" :disabled="userStudy == '1'">课程开班时间起</a-radio>
              </template>
              <!-- <template v-if="formData.freeTrialType != '1'">
                <a-radio value="3" :disabled="userStudy == '1'">课程免费试看部分结束时</a-radio>
              </template> -->
              <a-radio value="4" :disabled="userStudy == '1'">课程支付成功开始算起</a-radio>
              <template>
                <a-radio value="5" :disabled="userStudy == '1'">观看此章节视频开始算起</a-radio>
                <a v-if="formData.validStartType == '5' && userStudy !== '1'" style="margin-left:8px;" @click="onAddChapterValidDay()">选择章节</a>
                {{validStartSectionName}}
              </template>
            </a-radio-group>
          </div>
        </a-col>
      </a-row>
      <a-row class="rows" v-if="formData.validDayType != '2' && !offline">
        <a-col class="left required" span="4">课程延期设置</a-col>
        <a-col class="right" span="20">
          <a-radio-group class="radioGroup" v-model="formData.setDelay">
            <a-radio value="0" :disabled="userStudy == '1'">无需设置课程延期</a-radio>
            <a-radio value="1" :disabled="userStudy == '1'">需要设置课程延期</a-radio>
          </a-radio-group>
          <a-button type="primary" v-if="formData.setDelay == '1' && userStudy != '1'" @click="addCourseDelayList()">新增</a-button>
        </a-col>
      </a-row>
      <a-row class="rows" v-if="formData.setDelay == '1' && formData.validDayType != '2'" style="margin-top:0;">
        <a-col class="right" span="20" offset="4">
          <div class="fontSize" style="margin-top:10px;" v-for="(item,index) in formData.courseDelayList" :key="'courseDelayList'+item.uid">
            <span>第{{index+1}}次延期：</span>
            <a-input-number :precision="0" :min="1" :disabled="userStudy == '1'" style="width:120px;margin-left:8px;" v-model="item.days"/>
            <span style="margin-left:8px;">天，</span>
            <a-input-number :precision="2" :min="0" :disabled="userStudy == '1'" style="width:120px;margin-left:8px;" v-model="item.price"/>
            <span style="margin-left:8px;">元，赠送</span>
            <a-input-number :precision="0" :min="0" :disabled="userStudy == '1'" style="width:120px;margin-left:8px;" v-model="item.examNumber"/>
            <span style="margin-left:8px;">次考试机会</span>
            <template v-if="!(index < formData.courseDelayList.length - 1)">
              <span>，</span>
              <a-checkbox :disabled="userStudy == '1'" :defaultChecked="item.afterIsSame==1" @change="changeAfterIsSame($event,item)">多次延期同此</a-checkbox>
              <a v-if="userStudy != '1'" @click="removeCourseDelayList(item.uid)">删除</a>
            </template>
          </div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">课程考试设置</a-col>
        <a-col class="right" span="20">
          <a-radio-group class="radioGroup" v-model="formData.setExam">
            <a-radio :disabled="userStudy == '1'" value="0">无需考试</a-radio>
            <a-radio :disabled="userStudy == '1'" value="1">需要考试</a-radio>
          </a-radio-group>
          <template v-if="formData.setExam=='1'">
            <a-select :disabled="userStudy == '1'" style="width:240px;margin-left:8px;" v-model="formData.examCertifTempId" placeholder="请选择考试证书模板">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option :value="item.tempId" v-for="item in examCertifTempList" :key="'examCertifTempList'+item.tempId">{{item.name}}</a-select-option>
            </a-select>
            <template v-if="formData.examCertifTempId">
              <div style="border:1px solid #ccc;display:inline-block;vertical-align: text-top;margin: -5px 0 0 10px;">
                <img :src="examCertifTempImage" style="max-height:300px;max-width:300px;min-height:100px;min-width:100px;" alt="">
              </div>
            </template>
          </template>
        </a-col>
      </a-row>
      <a-row class="rows" v-if="!offline">
        <a-col class="left required" span="4">课程证书设置</a-col>
        <a-col class="right" span="20">
          <a-radio-group class="radioGroup" v-model="formData.setCourseCertificate">
            <a-radio :disabled="userStudy == '1'" value="0">课程无证书</a-radio>
            <a-radio :disabled="userStudy == '1'" value="1">课程有证书</a-radio>
            <a-radio :disabled="userStudy == '1'" value="2">学完 <a-input-number  :min="1" v-model="formData.watchCount" :disabled="formData.setCourseCertificate != '2' || userStudy == '1'" style="width:120px;margin-left:8px;"/>个章节可以获得
            </a-radio>
          </a-radio-group>
          <div class="fontSize" v-if="formData.setCourseCertificate == '1'">
            <span>学完以下章节可获得证书：</span>
            <a-button type="primary" v-show="userStudy != '1'" @click="addCourseCertificate()">新增</a-button>
            <div v-for="(item, index) in formData.courseCertificateList" :key="'courseCertificateList'+ index" style="margin-top:10px;">
              <a-tree-select
                :disabled="userStudy == '1'"
                v-model="item.value"
                style="width:240px;margin-left:8px;"
                tree-default-expand-all
                :tree-data="funFilterVideo(formData.courseCatalogueList)"
                :replaceFields="{children:'childList', title:'sectionName', key:'uid', value:'uid' }"
                placeholder="请选择章节"
              >
                <a-icon slot="suffixIcon" type="caret-down"/>
              </a-tree-select>
              <span style="margin-left:8px;">，关联证书</span>
              <a-select style="width:240px;margin-left:8px;" :disabled="userStudy == '1'" v-model="item.certifId" placeholder="请选择关联证书">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option :value="item.tempId" v-for="item in curriculumCertifTempList" :key="'curriculumCertifTempList'+item.tempId">{{item.name}}</a-select-option>
              </a-select>
              <a v-if="userStudy != '1'" style="margin-left:5px;" @click="removeCourseCertificate(item)">删除</a>
            </div>
          </div>
          <div class="fontSize" v-if="formData.setCourseCertificate == '2'">
             <a-select style="width:240px;margin-left:8px;" :disabled="userStudy == '1'" v-model="formData.courseCertifId" placeholder="请选择关联证书">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option :value="item.tempId" v-for="item in curriculumCertifTempList" :key="'curriculumCertifTempList'+item.tempId">{{item.name}}</a-select-option>
              </a-select>
          </div>
        </a-col>
      </a-row>
      <!-- <a-row class="rows" v-if="!offline && formData.setSectionTest==1 &&  SectionTestLength > 0"> -->
      <a-row class="rows">
        <a-col class="left required" span="4">小节自测设置</a-col>
        <a-col class="right" span="20">
          <!-- <a-radio-group class="radioGroup" v-model="formData.setSectionTest">
            <a-radio :disabled="userStudy == '1'" value="0">无小节自测</a-radio>
            <a-radio :disabled="userStudy == '1'" value="1"> -->
              <span>该课程下每节小节自测正确率达到</span>
              <a-input-number :precision="0" :min="0" style="width:100px;margin-left:8px;" v-model="formData.sectionTestCorrectRate"/>
              <span style="margin-left:8px;">%，即可通过</span>
            <!-- </a-radio>
          </a-radio-group> -->
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="20" offset="4">
          <a-button :loading="loadingBtn" type="primary" style="margin-right:30px;" @click="onStorage()">暂存</a-button>
          <a-button :loading="loadingBtn" @click="!offline?tabIndex=0:tabIndex=0" style="margin-right:30px;">上一步</a-button>
          <a-button :loading="loadingBtn" @click="tabIndex=3">下一步</a-button>
        </a-col>
      </a-row>
    </template>

    <!-- 购买设置 -->
    <template v-else-if="tabIndex == 3">
      <a-row class="rows">
        <a-col class="left required" span="4">所属商品类别</a-col>
        <a-col class="right" span="20">
          <a-tree-select
            v-model="formData.productCategoryIdList"
            style="width:240px;"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="productCategoryList"
            tree-checkable
            placeholder="请选择所属商品类别"
            :replaceFields="{
              children:'children', title:'name', key:'id', value: 'id' 
            }"
            :treeDefaultExpandAll="true"
          />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="4">商品价格设置</a-col>
        <a-col class="right" span="20">
          <div>
            <span>市场价(原价)：</span>
            <a-input-number :precision="2" :min="0" v-model="formData.originalPrice" style="width:120px;margin-left:8px;"/>
            <span style="margin-left:8px;">元，</span>
            <a-checkbox @change="changeSetOriginal" :checked="formData.setOriginal=='1'">是否在终端展示</a-checkbox>
          </div>
          <div style="margin-top:10px;">
            <span>优惠价：</span>
            <a-input-number :precision="2" :min="0" :max="formData.originalPrice" v-model="formData.couponPrice" :disabled="courseFree==1" style="width:120px;margin-left:8px;"/>
            <span style="margin-left:8px;">元</span>
            <!-- <a-checkbox>是否在终端展示</a-checkbox> -->
          </div>
        </a-col>
      </a-row>
      <!-- <a-row class="rows">
        <a-col class="left" span="4">机构购买设置</a-col>
        <a-col class="right" span="20">
          <span>机构价：</span>
          <a-input v-model="formData.organizationPrice" style="width:120px;margin-left:8px;"/>
          <span style="margin-left:8px;">元，需达到</span>
          <a-input v-model="formData.numberPeople" style="width:120px;margin-left:8px;"/>
          <span style="margin-left:8px;">人购买方可使用机构价</span>
          <div style="margin-top:10px;">
            <span>允许兑换券形式购买：</span>
            <a-radio-group class="radioGroup" v-model="formData.exchange">
              <a-radio value="0" style="line-height:32px;">否</a-radio>
              <a-radio value="1" style="line-height:32px;">是</a-radio>
            </a-radio-group>
          </div>
        </a-col>
      </a-row> -->
      <a-row class="rows">
        <a-col class="left" span="4">关联商品设置</a-col>
        <a-col class="right" span="19">
          <div>
            <span>关联商品：</span>
            <a-radio-group class="radioGroup" v-model="formData.setRelevantProduct">
              <a-radio value="0" style="line-height:32px;">无关联</a-radio>
              <a-radio value="1" style="line-height:32px;">
                <span>有关联</span>
                <a v-if="formData.setRelevantProduct=='1'" style="margin-left:14px;" @click="$refs.ModalProduct.open({type:'setRelevantProduct',selectedRowKey:funkeyId(setRelevantProductList),data:setRelevantProductList})">添加商品+</a>
              </a-radio>
            </a-radio-group>
          </div>
          <div style="margin-top:10px;" v-if="formData.setRelevantProduct=='1'">
            <a-table
              class="table-template"
              @change="onProductPage"
              :rowKey="item=>'setRelevantProductList'+item.productId"
              :columns="columns4"
              :data-source="setRelevantProductList"
              :pagination="{
                total:total,
                current: productNumber,
                pageSizeOptions: ['5','10','20','50'],
                defaultPageSize: productPageSize, 
                showSizeChanger: true,
                showTotal: function(total, range){
                return `共${total}条`}} ">
              <template slot="index" slot-scope="item, row, i">
                <div style="text-align: center;">
                  {{ (productNumber - 1) * productPageSize + i + 1 }}
                </div>
              </template>

              <template slot="operation" slot-scope="item, row">
                <div class="btn_router_link">
                  <a @click="removeRelevantProduct(row.productId)">删除</a>
                </div>
              </template>
            </a-table>
          </div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="4">赠品设置</a-col>
        <a-col class="right" span="19">
          <div>
            <span>关联赠品：</span>
            <a-radio-group class="radioGroup" v-model="formData.setRelevantGift">
              <a-radio value="0" style="line-height:32px;">无赠品</a-radio>
              <a-radio value="1" style="line-height:32px;">
                <span>有赠品</span>
                <a v-if="formData.setRelevantGift=='1'" style="margin-left:14px;" @click="$refs.ModalProduct.open({type:'setRelevantGift',selectedRowKey:funkeyId(setRelevantGiftList),data:setRelevantGiftList})">添加赠品+</a>
              </a-radio>
            </a-radio-group>
          </div>
          <div style="margin-top:10px;" v-if="formData.setRelevantGift=='1'">
            <a-table
              class="table-template"
              @change="onGiftPage"
              :rowKey="item=>'setRelevantGiftList'+item.productId"
              :columns="columns4"
              :data-source="setRelevantGiftList"
              :pagination="{
                total:total,
                current: giftPageNumber,
                pageSizeOptions: ['5','10','20','50'],
                defaultPageSize: giftPageSize, 
                showSizeChanger: true,
                showTotal: function(total, range){
                return `共${total}条`}} ">
              <template slot="index" slot-scope="item, row, i">
                <div style="text-align: center;">
                  {{ (giftPageNumber - 1) * giftPageSize + i + 1 }}
                </div>
              </template>

              <template slot="operation" slot-scope="item, row">
                <div class="btn_router_link">
                  <a @click="removesetRelevantGift(row.productId)">删除</a>
                </div>
              </template>
            </a-table>
          </div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="4">购买限制条件</a-col>
        <a-col class="right" span="20">
          <a-radio-group class="radioGroup" v-model="formData.setBuyLimit">
            <a-radio value="0">无购买限制</a-radio>
            <a-radio value="1">有购买限制</a-radio>
          </a-radio-group>
          <template v-if="formData.setBuyLimit=='1'">
            <div style="margin-top:10px;">
              <a-checkbox v-model="setBuyLimitType1" style="display:block;">在平台购买以下课程方可购买</a-checkbox>
              <div style="margin-top:10px;line-height:32px;" v-if="setBuyLimitType1">
                <span>选择课程</span>
                <a style="margin-left:8px;" @click="$refs.Modal.open({
                  title: '选择课程',
                  width: '1000px',
                  type: 'SelectCourse'
                })">添加 +</a>
                <div class="fontSize">
                  <div v-for="item in SelectCourseList" :key="'SelectCourseList'+item.courseId">
                    <span>{{item.courseName}}</span>
                    <a style="margin-left:8px;" @click="removeSelectCourseList(item)">删除</a>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top:24px;">
              <a-checkbox v-model="setBuyLimitType2" style="display:block;">人工审核相关资质通过后可购买</a-checkbox>
              <div style="line-height:32px;margin-top:10px;" v-if="setBuyLimitType2">
                <span>提交证明提示语</span>
                <a-textarea v-model="formData.buyPromptContent" style="width:360px;margin-left:8px;vertical-align: top;"/>
              </div>
            </div>
            <!-- <div style="margin-top:10px;">
              <a-checkbox v-model="setBuyLimitType3" style="display:block;">用户需要完成身份认证才可购买</a-checkbox>
            </div> -->
          </template>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="4">购买权限设置</a-col>
        <a-col class="right" span="20">
          <span>用户需要完成身份认证后才可购买：</span>
          <a-radio-group class="radioGroup" v-model="formData.userAuthentication">
            <a-radio value="1">需要</a-radio>
            <a-radio value="0">不需要</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row class="rows" v-show="false">
        <a-col class="left" span="4">商品上架设置</a-col>
        <a-col class="right" span="20">
          <a-radio-group class="radioGroup" v-model="formData.groundSet">
            <a-radio value="1">暂不上架</a-radio>
            <a-radio value="2">提交即上架</a-radio>
            <a-radio value="3" style="line-height:32px;">
              <span>设置上架时间</span>
              <a-date-picker
                show-time
                :disabled-date="disabledDate"
                :disabledTime="disabledDateTime"
                :disabled="formData.groundSet!='3'"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                v-model="formData.groundTime"
                style="margin-left:10px;"
                placeholder="设置上架时间"
              />
            </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="20" offset="4">
          <a-button :loading="loadingBtn" type="primary" style="margin-right:30px;" @click="onStorage()">暂存</a-button>
          <a-button :loading="loadingBtn" @click="tabIndex=2" style="margin-right:30px;">上一步</a-button>
          <a-button :loading="loadingBtn" @click="onSave()">提交</a-button>
        </a-col>
      </a-row>
    </template>

    <!-- 弹出窗 -->
    <Modal ref="Modal" @ok="onModal"/>

    <!-- 弹窗 - 选择商品 -->
    <ModalProduct ref="ModalProduct" @ok="onModalProduct"/>

    <!-- 导入确认 -->
    <a-modal v-model="importConfirmation" title="提示">
      <p>导入目录后将清空之前的目录名称、主讲老师、视频及小节自测的数据，是否继续导入？</p>
      <template slot="footer">
        <a-button style="margin-right:30px;" @click="importConfirmation=false">放弃</a-button>
        <a-upload
          :showUploadList="false"
          name="CatalogTemplate"
          :customRequest="fileAction"
        >
          <a-button type="primary" @click="importConfirmation=false">继续</a-button>
        </a-upload>
      </template>
    </a-modal>
    
    <!-- 数据弹窗 -->
    <LeadingIn
      :columns="columnsss"
      :successList="successList"
      :failureList="failureList"
      :visible="importVisible"
      :handleCancel="modelhandleCancel"
    />
  </div>
</template>

<script>
import config from './CourseDetails/config.vue'
export default {
  mixins: [ config ],
  // 可用组件的哈希表
  components: { },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      formData: {
        /* 基础配置 */
        courseName: '', // 课程名称
        courseLikeIdList: [], // 关联课程偏好
        mobileDetailPicture: '', // 移动端详情图
        mobileListPicture: '', // 移动端列表图
        mobileChiefPicture: '', // 移动端商品图
        pcListPicture: '', // PC端列表图
        pcDetailPicture: '', // PC端详情图
        customerCode: '', // 客服二维码
        customerIntroduce: '', // 客服介绍
        introduction: '', // 课程简介
        classHour: '', // 总课时
        courseCategoryId: undefined, // 课程类别
        codeType: '1', // 商品编码1自产，2外销
        productCode: '', // 商品编码
        courseTeacherIdList: [], // 授课老师
        name: '', // 证书名称
        courseRelevantCertificate: [], // 相关证书,示例值([ "name", "picture" ])	
        mobileDetail: '', // 移动端课程详情
        pcDetail: '', // PC端课程详情
        mobileStudentInstructions: '', // 移动端学员须知
        pcStudentInstructions: '', // PC端学员须知
        // mobileRegistrationGuide: '', // 移动端报名指南
        // pcRegistrationGuide: '', // PC端报名指南
        /* 基础配置 Esc */

        /* 视频管理 */
        setCatalogue: 1, // 是否设置目录1.是 0.否
        courseCatalogueList: [], // 课程章节目录,示例值([ { "name": "章节1", "videoFileId": "https://www.hxclass.cn/a.mp4", "teacherIdList": 1, "duration": "05:00" } ])
        courseCatalogueList1: [], // 课程章节目录展示,示例值([ { "name": "章节1", "videoFileId": "https://www.hxclass.cn/a.mp4", "teacherIdList": 1, "duration": "05:00" } ])
        freeTrialType: '1', // 免费试看类型1.不设置免费试看 2.只第一节可免费试看 3.多章节可试看
        freeTrialMinute: '', // 免费试看分钟数
        firstWatchType: false, // 首次观看是否可拖拽1.是 0.否
        againWatch: true, // 再次观看是否可拖拽1.是 0.否
        firstSpeedType: false, // 首次观看是否可倍速1.是 0.否
        againSpeed: true, // 再次观看是否可倍速1.是 0.否
        watchByCatalogueOrder: true, // 是否必须按目录顺序进行观看1.是 0.否
        watchLimitType: null, // 课程观看限制1.必须通过以下课程的考试 2.拥有以下课程的考试证书 3.二者皆可
        courseWatchLimitList: [], // 课程观看条件限制
        /* 视频管理 Esc */

        /* 特殊设置 */
        offlineSameAddr: '1', // 线下培训场次是否在同一地点1.是 0.否
        setOpenClass: '0', // 是否设置开班时间1.是 0.否
        courseOpenTimeList: [], // 课程开班时间,示例值([ { "type": 1, "className": "第一期", "startTime": "2022-03-15", "endTime": "2022-04-15", "number": 50, "province": "100000", "city": "100000", "district": "100000", "address": "怡和阳光大厦" } ])	
        validDayType: '1', // 有效期类型1.固定期限2.长期
        validDays: '', // 有效期天数
        validStartType: '0', // 	有效期开始计算节点1.从做某小节自测算起 2.课程开班时间算起 3.课程免费试看部分结束时开始算起 4.课程支付成功开始算起 5.观看章节视频开始算起
        validStartSectionId: '',// 有效期开始计算节点为1时，此为某章节目录id
        setDelay: '0', // 是否设置课程延期1.是 0.否
        courseDelayList: [], // 课程延期设置,示例值([ { "afterIsSame": 0, "days": 0, "examNumber": 0, "price": 0, "sort": 0 } ])
        setExam: '0', // 是否需要考试1.是 0.否
        examCertifTempId: undefined, // 考试证书模板id
        setCourseCertificate: '0', // 是否有课程证书1.是 0.否
        courseCertificateList: [], // 课程证书
        courseCertifId: null, // 课程模板id
        watchCount: 1, // 课程数量
        setSectionTest: '0', // 是否设置小节自测1.是 0.否
        sectionTestCorrectRate: '100', // 小节自测正确率
        /* 特殊设置 Esc */

        /* 购买设置 */
        productCategoryIdList: [], // 所属商品类别
        originalPrice: 0, // 市场价（原价）
        setOriginal: '0', // 市场价（原价）是否在终端展示
        couponPrice: '', // 优惠价
        organizationPrice: '', // 机构价
        numberPeople: '', // 机构购买人数限制
        exchange: '1', // 允许兑换券形式购买1.是 0.否
        setRelevantProduct: '0', // 是否关联商品1.是 0.否
        setRelevantGift: '0', // 是否关联赠品1.是 0.否
        setBuyLimit: '0', // 是否有购买限制1.有 0.没有
        buyLimitType: '', // 购买条件类型1.在平台购买以下课程方可购买 2.人工审核相关资质通过后可购买 3.二者皆可
        buyPromptContent: '', // 提交证明提示语
        userAuthentication: '0', // 用户需要完成身份认证后才可购买1.是 0.否
        groundSet: '1', // 上架设置1.暂不上架2.立即上架3.设置上架时间
        groundTime: undefined, // 上架时间
        /* 购买设置 Esc */
      },
      // 开班时间分页
      openTimeNumber: 1,
      openTimePageSize: 5, //条数
      // 关联商品分页
      productNumber: 1,
      productPageSize: 5, //条数
      // 赠品分页
      giftPageNumber: 1,
      giftPageSize: 5, //条数

      // 观看此章节视频开始算起
      validStartSectionName: '', //章节名称
    }
  },
  // 事件处理器
  methods: {
    // 切换开班时间
    onOpenTimePage(e) {
      this.openTimeNumber = e.current
      this.openTimePageSize = e.pageSize
    },
    // 切换关联商品
    onProductPage(e) {
      this.productNumber = e.current
      this.productPageSize = e.pageSize
    },
    // 切换赠品分页
    onGiftPage(e) {
      this.giftPageNumber = e.current
      this.giftPageSize = e.pageSize
    },

    // tree显示的icon
    expandIcon(props) {
      if(props.record.childList && props.record.childList.length > 0){
        if (props.expanded) {
          //有数据-展开时候图标
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-down" />{" "}
            </a>
          );
        } else {
          //有数据-未展开时候图标
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-right" />
            </a>
          );
        }
      }else{
        return <span style={{marginRight:8 }}></span>
      }
    },

    // 文件上传前钩子上传图片
    beforeUpload(file){
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 文件上传
    customRequest(fileData) {
      this.uploadLoading = true
      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          if(fileData.filename.indexOf('courseRelevantCertificate')!=-1){
            // 相关证书图片上传
            const index = fileData.filename.split('-')[1]
            let arr = this.formData.courseRelevantCertificate
            arr[index].picture = url
            this.$set(this.formData,'courseRelevantCertificate',arr)
          }else{
            this.$set(this.formData, fileData.filename, url)
          }
        } else {
          this.$message.error("上传失败");
        }
        this.uploadLoading = false
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 重置文件上传 - 多文件
    customRequest2(fileData){
      this.uploadLoading2 = true
      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          let arr = []
          this.courseRelevantCertificateList.forEach(item=>{
            if(item.uid == this.file.uid){
              item.status = 'success'
              item.url = url
              arr.push(url)
            }else{
              arr.push(item.url)
            }
          })
          this.formData.courseRelevantCertificate = arr
        } else {
          this.$message.error("上传失败");
        }
        this.uploadLoading2 = false
      });
    },

    // 富文本内容有变化时触发
    getContent(txt,name) {
      this.$set(this.formData, name, txt);
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    'formData.courseCatalogueList': {
      handler(newVal, oldVal){
        // console.log('newVal', newVal)
        // console.log('oldVal', oldVal)
        // 列表展示的数据
        function fors(arr){
          let data = arr.filter(item=>{
            return !item.deleted
          })
          data.forEach(item=>{
            if(item.childList && item.childList.length>0){
              item.childList = fors(item.childList)
            }
          })
          return data
        }
        if(!newVal) {
          return this.formData.courseCatalogueList1 = []
        }
        this.formData.courseCatalogueList1 = fors(JSON.parse(JSON.stringify(newVal)))
      },
      immediate: true,
      deep: true
    },
    // 列表展示的数据
    // function fors(arr){
    //   let data = arr.filter(item=>{
    //     return !item.deleted
    //   })
    //   data.forEach(item=>{
    //     if(item.childList && item.childList.length>0){
    //       item.childList = fors(item.childList)
    //     }
    //   })
    //   return data
    // }
    // this.formData.courseCatalogueList1 = fors(JSON.parse(JSON.stringify(this.formData.courseCatalogueList)))
    // console.log(this.formData.courseCatalogueList1)
  }
}
</script>
